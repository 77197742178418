import JoinTheTeam from '../../../assets/bannerlinks/jointheteam.jpg'
import TakeOff from '../../../assets/bannerlinks/takeoff.jpg'

const boxes = [
  {
    text: 'Join the team!',
    image: JoinTheTeam,
    link: '/karriere',
    linkText: 'Bewerbung'
  },
  {
    text: 'Ready to take off?',
    image: TakeOff,
    link: '/kontakt',
    linkText: 'Kontakt'
  }
]

export default boxes
