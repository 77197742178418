import Farbverlauf from '../../../assets/verlauf.jpg'
import SandsofTime from '../../../assets/sandsoftime.jpg'
import Mouse from '../../../assets/mouse.opt.gif'
import HandyNav from '../../../assets/handynavigation.jpg'
import HowIsYourDay from '../../../assets/splitscreens.jpg'
import matomo_m from '../../../assets/matomo_m.png'
import ie_grave from '../../../assets/internet-explorer-gravestone-overlay.png'

const cards = [
  {
    tag: 'Karriere',
    headline: 'Interview zum Girls Day 2022',
    backgroundImage: null,
    readingTime: null,
    link: '/blog/girlsday-2022'
  },
  {
    tag: 'Technologie',
    headline: 'Der Internet Explorer wird eingestellt',
    backgroundImage: ie_grave,
    readingTime: null,
    whiteColor: true,
    link: '/blog/internet-explorer-death'
  },
  {
    tag: 'DSGVO',
    headline: 'matomo statt Google Analytics!',
    subheadline: 'Meiden auch Sie hohe Bußgeldstrafen',
    backgroundImage: matomo_m,
    readingTime: null,
    link: '/blog/matomo_statt_google/'
  },
  // {
  //   tag: 'Technologie',
  //   headline: 'DSGVO - einfach!',
  //   subheadline: 'DSGVO Checkliste zum Abhaken',
  //   backgroundImage: null,
  //   readingTime: 2,
  //   link: '/blog/dsgvo-einfach/'
  // },
  {
    tag: 'Projektmanagement',
    headline: 'Ticketsystem',
    subheadline: 'Strukturierte Kommunikation während des Projektes',
    backgroundImage: null,
    readingTime: null,
    blackColor: true,
    link: '/blog/customer-portal/'
  },
  {
    tag: 'UI - User Interface',
    headline: 'Gradient your life',
    subheadline: 'Farbverläufe und Transitions',
    backgroundImage: Farbverlauf,
    readingTime: null,
    whiteColor: true,
    link: '/blog/gradient-your-life/'
  },
  {
    tag: 'UI - User Interface',
    headline: 'Bild-befüllter Text',
    subheadline: 'Stylish.Punkt.',
    backgroundImage: SandsofTime,
    readingTime: null,
    link: '/blog/bild-befuellter-text/'
  },
  {
    tag: 'UI - User Interface',
    headline: 'Mobiles Menü ganz unten',
    subheadline: 'Daumen brauchen neue Menüführung',
    backgroundImage: HandyNav,
    readingTime: 5,
    whiteColor: true,
    link: '/blog/mobilenavigationganzunten/'
  },
  {
    tag: 'Technologie',
    headline: 'Cross-Testing',
    subheadline: 'Wie funktioniert das?',
    backgroundImage: null,
    readingTime: 1,
    link: '/blog/cross-testing/'
  },
  {
    tag: 'UX - User Experience',
    headline: 'Micro Interaction',
    subheadline: 'Verbesserung der User Experience',
    backgroundImage: Mouse,
    readingTime: null,
    link: '/blog/micro-interaction/'
  },
  {
    tag: 'UI - User Interface',
    headline: 'Split Screens',
    subheadline: 'Dualität mit Style Faktor',
    backgroundImage: HowIsYourDay,
    readingTime: null,
    whiteColor: true,
    link: '/blog/split-screens/'
  },
  {
    tag: 'SEO',
    headline: '95% Uplift mit SEO',
    subheadline: 'Reichweite der Webseite maximieren',
    backgroundImage: null,
    readingTime: 5,
    link: '/blog/mittels-seo-zur-besten-platzierung'
  }
]

export default cards
